import React, { useState } from "react"
import { FiArrowUp, FiUser } from "react-icons/fi"
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai"
import { RiFacebookFill } from "react-icons/ri"
import { FiLinkedin, FiEdit } from "react-icons/fi"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Pin } from "../assets/images/mapPin"
import useForm from "./hooksComponents/useForm"
import { isValidEmail, scrollTop } from "../utils"
import { postContact } from "../actions/dataActions"
import { FormattedMessage } from "react-intl"
import OutLineMail from "../assets/icons/outLineMail"
import useMediaQuery from "./hooksComponents/useMediaQuery"

const ContactUsComponent = props => {
  const { state, onChange, setState, setErrors, errors } = useForm({
    name: "",
    email: "",
    message: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useMediaQuery("(max-width: 991px)")
  function openInNewTab(url) {
    window.open(url, "_blank").focus()
  }
  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    if (!state.name) {
      setErrors({
        ...errors,
        required: { name: true },
      })
      setIsLoading(false)
      return
    }
    if (!state.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      setIsLoading(false)
      return
    }
    if (!isValidEmail(state.email)) {
      setErrors({
        ...errors,
        invalid: { email: true },
      })
      setIsLoading(false)
      return
    }
    if (!state.message) {
      setErrors({
        ...errors,
        required: { message: true },
      })
      setIsLoading(false)
      return
    }
    props
      .postContact(state)
      .then(() => {
        setIsLoading(false)

        setState({
          name: "",
          email: "",
          message: "",
        })
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  return (
    <div className="relative gradientBg mt-4 px-6rem">
      <div className="row">
        <div
          className="col-lg-3 col-md-6 col-
        12 py-lg-5"
        >
          <div>
            <p className="greyLight32 footerFontTitles font-bold text-uppercase">
              <FormattedMessage defaultMessage="Follow Us" />
            </p>
            <div className="primary">
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.facebook.com")}
              >
                <RiFacebookFill size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.linkedin.com")}
              >
                <FiLinkedin
                  size={isMobile ? "2.5rem" : "1.5rem"}
                  style={{ fill: "#3C3F58" }}
                />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.instagram.com")}
              >
                <AiOutlineInstagram size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.twitter.com")}
              >
                <AiOutlineTwitter size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
            </div>
          </div>

          <div className="d-lg-flex flex-wrap mt-lg-5 my-3 flex-lg-nowrap">
            <div className="mr-lg-5">
              <p className="greyLight32 footerFontTitles font-bold text-uppercase">
                <FormattedMessage defaultMessage="Get In Touch" />
              </p>
              <div className="d-flex mb-3">
                <Pin />
                <div className="mx-2 primary footerFont">
                  <p className="font-bold mb-1">Abo Romana, Damascus, Syria</p>
                  <p className="mb-0">+96 9999 999 999</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <Pin />
                <div className="mx-2 primary footerFont">
                  <p className="font-bold mb-1">
                    Dubai, Jumariah Park, Building 27
                  </p>
                  <p className="mb-0">+96 9999 999 999</p>
                </div>
              </div>
            </div>
          </div>
          <div className="me-lg-5">
            <p className="greyLight32 footerFontTitles font-bold text-uppercase">
              <FormattedMessage defaultMessage="More Links" />
            </p>
            <div className="primary footerFont font-bold">
              <p>
                <Link to="/aboutUs">
                  <FormattedMessage defaultMessage="About Us" />
                </Link>
              </p>
              <p>
                <Link to="/">
                  <FormattedMessage defaultMessage="Properties for sell" />
                </Link>
              </p>
              <p>
                <Link to="/">
                  <FormattedMessage defaultMessage="Properties for rent" />
                </Link>
              </p>
              <p>
                <Link to="/">
                  <FormattedMessage defaultMessage="List a service" />
                </Link>
              </p>
              <p>
                <Link to="/">
                  <FormattedMessage defaultMessage="List an ad for free" />
                </Link>
              </p>
              <p>
                <Link to="/terms">
                  <FormattedMessage defaultMessage="Terms of Services" />
                </Link>
              </p>
              <p>
                <Link to="/policy">
                  <FormattedMessage defaultMessage="Privacy Policy" />
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div
          style={{ paddingBottom: isMobile ? 100 : 0 }}
          className="col-lg-9 col-md-6 col-12 py-lg-5"
        >
          <div>
            <p
              className="greyLight32 footerFontTitles font-bold text-uppercase"
              style={{ marginBottom: 0 }}
            >
              <FormattedMessage defaultMessage="Send Feedbacks" />
            </p>
            <span
              onClick={() => window.open("mailto:hello@jasmins.com", "_tab")}
              className="primary pointer font-bold px32"
            >
              hello@jasmins.com
            </span>
            <div className="d-flex justify-content-end d-lg-none">
              <button
                className="font-bold px16 br8 white secondaryBg mb-5 button-shadow p-3 button-secondaryBorder"
                onClick={scrollTop}
              >
                <FiArrowUp size="2rem" />
              </button>
            </div>
          </div>

          <div>
            <form className="mt-lg-5 my-3" onSubmit={handleSubmit}>
              <p
                className="greyLight32 footerFontTitles font-bold text-uppercase"
                style={{ marginBottom: "15px" }}
              >
                <FormattedMessage defaultMessage="Contact Us" />
              </p>
              <div
                className="d-flex align-items-center flex-wrap flex-lg-nowrap margin-bottom-10"
                style={{ marginTop: "-20px" }}
              >
                <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 my-2 z-99">
                  <input
                    name="name"
                    type="text"
                    className="greyLightBg black br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Your name"
                    value={state.name}
                    onChange={onChange}
                  />
                  <div>
                    <FiUser size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.name && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Name is required!" />
                  </span>
                )}
                <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 mx-lg-5 my-2 z-99">
                  <input
                    name="email"
                    type="text"
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="E-mail"
                    value={state.email}
                    onChange={onChange}
                  />
                  <div>
                    <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.email && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Email is required!!" />
                  </span>
                )}
                {errors.invalid.email && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Email is invalid!!" />
                  </span>
                )}
              </div>

              <div className="d-flex flex-wrap flex-lg-nowrap">
                <div className="signInInput greyLightBg br4 d-flex align-items-start my-2 z-99">
                  <textarea
                    name="message"
                    className="greyLightBg br4 flex-grow-1 px18"
                    placeholder="Leave your message"
                    rows="2"
                    cols="2"
                    value={state.message}
                    onChange={onChange}
                  />
                  <div>
                    <FiEdit size="1.5rem" className="darkGrey mx-3 my-3" />
                  </div>
                  {errors.required.message && (
                    <span className="error mx-2">
                      <FormattedMessage defaultMessage="Message is required!!" />
                    </span>
                  )}
                </div>

                <button
                  className="z-99 primaryBg px24 white br8 signInButton btnShadow d-flex align-items-center justify-content-center mx-lg-5 my-2 button-primaryBorder"
                  style={{ height: "80px" }}
                >
                  {isLoading ? (
                    <span
                      style={{ height: 30, width: 30 }}
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span className="font-bold">
                      <FormattedMessage defaultMessage="SEND" />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { postContact })(ContactUsComponent)
