import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader"
import { connect } from "react-redux"
import { FiArrowUp } from "react-icons/fi"
import defaultImage from "../assets/images/default.png"
import { FormattedMessage } from "react-intl"
import api from "../_api/dataApi"
import { error, scrollTop } from "../utils"
import { Box } from "@material-ui/core"
import footer from "../assets/images/footer.png"
import ContactUsComponent from "../components/contactUsComponent"

const AboutUs = ({ lang }) => {
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    api
      .getConstantPages("privacy-policy")
      .then(res => {
        setContent(res?.content)
      })
      .catch(() => error())
      .finally(() => setLoading(false))
  }, [lang])

  return (
    <Layout>
      <SEO title="About Us" />
      {loading && <Loader />}
      <div>
        <ContactUsComponent />
        <img
          src={footer}
          alt={"footer"}
          className="absolute right-0 bottom-0"
        />
        <div className="d-flex align-items-end justify-content-center w-100 absolute bottom-0 text-center mb-5">
          <div className="primary font-bold px14 py-5 mb-5 mb-lg-0">
            <FormattedMessage defaultMessage="Copyright © Simsar 2021. All Rights Reserved 2021. All Rights Reserved" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ settings: { lang } }) => ({ lang })
export default connect(mapStateToProps)(AboutUs)
